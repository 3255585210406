<template>
  <div>
    <br /><br /><br /><br />
    <section>
      <article class="container mt-5">
        <div>
          <h1 style="font-size: 1.6em; font-weight: 600; text-align: left;">Trekker's Tavern Cottages Gallery</h1>
          <hr />
        </div>
        <div class="trekkers_gallery">
          <div
            class="demo-image__preview"
            v-for="(list, index ) in srcList"
            :key="index"
          >
            <el-image
              :src="list"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      srcList: [
        require("../assets/images/cottages/20220608_164704.jpg"),
        require("../assets/images/cottages/20220608_174802.jpg"),
        require("../assets/images/cottages/IMG-20191001-WA0133.jpg"),

        require("../assets/images/home_image1.jpg"),
        require("../assets/images/cottages/20220608_100948.jpg"),
        require("../assets/images/cottages/20220608_101133.jpg"),
        require("../assets/images/cottages/20220608_101144.jpg"),
        require("../assets/images/cottages/20220608_164433.jpg"),
        require("../assets/images/cottages/20220608_164704.jpg"),
        require("../assets/images/cottages/20220608_165115.jpg"),
        require("../assets/images/cottages/20220608_165418.jpg"),
        require("../assets/images/cottages/20220608_165442.jpg"),

        require("../assets/images/cottages/20220608_165530.jpg"),
        require("../assets/images/cottages/20220608_174802.jpg"),
        require("../assets/images/cottages/20220608_133842.jpg"),
        require("../assets/images/cottages/20220608_134119.jpg"),
        require("../assets/images/cottages/20220608_134154.jpg"),
        require("../assets/images/cottages/20220608_151529.jpg"),
        require("../assets/images/cottages/20220608_163950.jpg"),
        require("../assets/images/cottages/20220608_164022.jpg"),
      ],
    };
  },

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.trekkers_gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 100px;
}

.demo-image__preview {
  background-color: #3d290a;
}
/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .trekkers_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .trekkers_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }
}
</style>

<style>
.trekkers_gallery .demo-image__preview .el-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.trekkers_gallery .demo-image__preview {
  width: 100%;
  height: 200px;
}
.trekkers_gallery .demo-image__preview .el-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
</style>
